import { useEffect, useState } from "react";
import i18next from "i18next";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import "../config/styles/main.css";
import { Container } from "@mui/system";

export const ReservationStatus: React.FC = () => {
  const date = moment().format("yyyy-MM-DD");
  const [dateValue, setDateValue] = useState<any>(date);
  const [tableData, setTableData] = useState<Array<string>>([]);

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = moment(new Date(e.target.value)).format("yyyy-MM-DD");
    setDateValue(newDate);
  };

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_API_URL +
      "/management/reservations/status?date=" +
      dateValue;
    const fetchCall = () => {
      fetch(API_URL, {
        headers: {
          "x-api-key": process.env.REACT_APP_APIKEY!!,
        },
      })
        .then((r) => r.json())
        .then((response) => {
          setTableData(response);
        });
    };
    fetchCall();
    const fetchInterval = setInterval(fetchCall, 10 * 1000);
    return () => clearInterval(fetchInterval);
  }, [dateValue]);

  return (
    <Grid item xs={12}>
      <Container>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          <Typography
            color="#002554"
            fontSize="2.25rem"
            textAlign="center"
            margin="3rem 0"
            variant="h1"
          >
            {`${i18next.t("shared:reservations.header")}`}
            {moment(dateValue).format("MMMM DD, YYYY")}
          </Typography>

          <TextField
            id="date"
            name="dateChange"
            className="dateInput"
            type="date"
            onChange={onChangeDate}
            defaultValue={dateValue}
            sx={{ width: 200 }}
          ></TextField>
        </Grid>
        <Grid>
          {tableData && (
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                align="center"
                sx={{ maxWidth: "100%", minHeight: "150px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        border: "1.5px solid #002554",
                      }}
                      align="center"
                    >
                      {`${i18next.t("shared:reservations.tableInfo.pp")}`}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        border: "1.5px solid #002554",
                      }}
                      align="center"
                    >
                      {`${i18next.t("shared:reservations.tableInfo.lp")}`}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        border: "1.5px solid #002554",
                      }}
                      align="center"
                    >
                      {`${i18next.t("shared:reservations.tableInfo.status")}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((p: any, i: number) => (
                    <TableRow key={i} sx={{ border: 0 }}>
                      <TableCell
                        style={{
                          fontSize: "1.6rem",
                          border: "1.5px solid #DC8633",
                        }}
                        align="center"
                      >
                        {p.parkingSpaceName}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "1.6rem",
                          border: "1.5px solid #DC8633",
                        }}
                        align="center"
                      >
                        {p.licensePlate}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "1.6rem",
                          border: "1.5px solid #DC8633",
                        }}
                        align="center"
                      >
                        {p.reservationStatus}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};
