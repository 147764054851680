
import './App.css';
import { ReservationStatus } from './pages/ReservationStatus';

function App() {
  return (
    <div >
      <ReservationStatus></ReservationStatus>
    </div>
  );
}

export default App;
